import { getWindow } from "@utils";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderMetadata from "components/HeaderMetadata";
import commonConfig from "config/common";
import { AppEncryptionProvider } from "context/AppEncryptionContext";
import { useIsHydrating } from "hooks/components/useHydrating";
import DOMPurify from "isomorphic-dompurify";
import { NextPageContext } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";
import LiveChat from "utils/LiveChat";
import gtmUtils from "utils/gtm";
import { buildFontFamilyStyle } from "utils/styles";
import { scanAnchorTags } from "utils/utm";
import "../@fwd-dep/nextgen-ui-lib/dist/esm/assets/ui-lib.css";
import "../styles/globals.scss";
import styled from "styled-components";

import { PopupHolder } from "shared-components/Popup/PopupHolder";
interface Props extends AppProps {
  supportedLanguages: Record<
    string,
    { locale: string; languageCode: string; displayName: string }
  >;
  ctx: NextPageContext;
}

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

function App({ Component, pageProps }: Props) {
  const isHydrating = useIsHydrating();
  const [googleScripts, setGoogleScripts] = useState({
    gtmScripts: "",
    optScripts: "",
    antiFlickerEnabled: false,
  });

  const onReloadPage = () => {
    getWindow()?.location?.reload();
  };

  useEffect(() => {
    getWindow()?.addEventListener("popstate", () => onReloadPage);
    return () => getWindow()?.removeEventListener("popstate", onReloadPage);
  }, []);

  const handlePushEvent = () => {
    setTimeout(() => {
      gtmUtils.pushEvent({
        event: "print",
      });
    }, 0);
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          scanAnchorTags();
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(document, {
      attributes: false,
      childList: true,
      subtree: true,
    });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (window !== undefined) {
      setTimeout(() => {
        LiveChat();
      }, 0);
      getWindow()?.addEventListener("afterprint", handlePushEvent);
    }
    return () =>
      getWindow()?.removeEventListener("afterprint", handlePushEvent);
  }, []);

  useEffect(() => {
    let newGoogleScripts = {
      antiFlickerEnabled: false,
      gtmScripts: "",
      optScripts: "",
    };

    newGoogleScripts.antiFlickerEnabled =
      (pageProps as any)?.data?.antiFlickerEnabled || false;
    newGoogleScripts.gtmScripts =
      (pageProps as any)?.siteSettings?.embeded_scripts?.google_tag_manager ||
      "";
    newGoogleScripts.optScripts =
      (pageProps as any)?.siteSettings?.embeded_scripts?.google_optimize || "";

    setGoogleScripts(newGoogleScripts);
  }, [
    (pageProps as any)?.data,
    (pageProps as any)?.siteSettings?.embeded_scripts?.google_optimize,
    (pageProps as any)?.siteSettings?.embeded_scripts?.google_tag_manager,
  ]);

  const seoData = (pageProps as any)?.data?.layout?.find(
    (item: any) => item.nameComponent === "NextHead"
  )?.dataComponent;
  const supportedLanguages = (pageProps as any)?.supportedLanguages || null;

  const splitedGtmScript =
    (
      pageProps as any
    )?.siteSettings?.embeded_scripts?.google_tag_manager?.split("GTM-") || [];
  const gtmId =
    splitedGtmScript?.[splitedGtmScript?.length - 1]?.replace(
      /[^a-zA-Z0-9]/g,
      ""
    ) || null;

  const siteSettings = (pageProps as any)?.siteSettings;

  return (
    <>
      {seoData && supportedLanguages && (
        <HeaderMetadata
          data={seoData}
          supportedLanguages={supportedLanguages}
          publicExportSite={
            (pageProps as any)?.publicConfigurations?.publicExportSite
          }
        />
      )}
      <Head>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: buildFontFamilyStyle(
              siteSettings?.font_family,
              siteSettings?.font_face
            ),
          }}
        />
        {/* Google Tag Manager - Global base code */}
        <script
          dangerouslySetInnerHTML={{
            __html: googleScripts.gtmScripts,
          }}
          defer
        />
        {gtmId && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'GTM-${gtmId}', {
                    page_path: window.location.pathname,
                  });
                  `,
            }}
          />
        )}
        {googleScripts.antiFlickerEnabled && (
          <>
            {/* <!-- anti-flicker snippet (recommended)  --> */}
            <style
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(commonConfig.antiFlickerStyleInit()),
              }}
            />
          </>
        )}
        {googleScripts.optScripts && (
          <script
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(googleScripts.optScripts),
            }}
          />
        )}
      </Head>
      <MainDiv>
        <Component {...pageProps} />
      </MainDiv>
      <PopupHolder />
    </>
  );
}

function CustomApp(props: any) {
  return (
    <AppEncryptionProvider {...props}>
      <App {...props} />
    </AppEncryptionProvider>
  );
}
export default CustomApp;
